{
  "name": "dotenv",
  "version": "16.4.7",
  "description": "Loads environment variables from .env file",
  "main": "lib/main.js",
  "types": "lib/main.d.ts",
  "exports": {
    ".": {
      "types": "./lib/main.d.ts",
      "require": "./lib/main.js",
      "default": "./lib/main.js"
    },
    "./config": "./config.js",
    "./config.js": "./config.js",
    "./lib/env-options": "./lib/env-options.js",
    "./lib/env-options.js": "./lib/env-options.js",
    "./lib/cli-options": "./lib/cli-options.js",
    "./lib/cli-options.js": "./lib/cli-options.js",
    "./package.json": "./package.json"
  },
  "scripts": {
    "dts-check": "tsc --project tests/types/tsconfig.json",
    "lint": "standard",
    "pretest": "npm run lint && npm run dts-check",
    "test": "tap run --allow-empty-coverage --disable-coverage --timeout=60000",
    "test:coverage": "tap run --show-full-coverage --timeout=60000 --coverage-report=lcov",
    "prerelease": "npm test",
    "release": "standard-version"
  },
  "repository": {
    "type": "git",
    "url": "git://github.com/motdotla/dotenv.git"
  },
  "funding": "https://dotenvx.com",
  "keywords": [
    "dotenv",
    "env",
    ".env",
    "environment",
    "variables",
    "config",
    "settings"
  ],
  "readmeFilename": "README.md",
  "license": "BSD-2-Clause",
  "devDependencies": {
    "@types/node": "^18.11.3",
    "decache": "^4.6.2",
    "sinon": "^14.0.1",
    "standard": "^17.0.0",
    "standard-version": "^9.5.0",
    "tap": "^19.2.0",
    "typescript": "^4.8.4"
  },
  "engines": {
    "node": ">=12"
  },
  "browser": {
    "fs": false
  }
}
