require('dotenv').config()
var { FIREBASE_API_URL } = process.env;
console.log("FIREBASE_API_URL", FIREBASE_API_URL);
WebFont.load({
    google: {
        families: [
            "Open Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic",
            "Manrope:200,300,regular,500,600,700,800",
            "Rubik:300,regular,500,600,700,800,900,300italic,italic,500italic,600italic,700italic,800italic,900italic",
        ],
    },
});
// Function to detect platform (Android, iOS, Desktop)
function getPlatform() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return 'Android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    } else {
        return 'Desktop';
    }
}

// Function to check iOS version
function getiOSVersion() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const match = userAgent.match(/OS (\d+)_/i);
    return match ? parseInt(match[1], 10) : null;
}

// Function to check if the app is installed by attempting to open a deep link
function openAppWithDeepLink(deepLink, fallbackLink) {
    console.log("Opening Deep Link:", deepLink);
    const startTime = Date.now();

    // Attempt to open the app with the deep link
    window.location.href = deepLink;

    // Redirect to fallback if the app doesn't open
    setTimeout(() => {
        const elapsedTime = Date.now() - startTime;

        // If elapsed time is less than a threshold, app didn't open
        if (elapsedTime < 1500) {
            console.log("App not detected, redirecting to fallback link.");
            window.location.href = fallbackLink;
        }
    }, 1500); // Increase timeout slightly for better handling
}

// Main function to handle dynamic link routing
function handleDynamicLink(result) {
    const platform = getPlatform();
    const iOSVersion = getiOSVersion();
    console.log("platform", platform);
    console.log("iOSVersion", iOSVersion);

    console.log("result", result);
    // Define your deep links and store links
    const appDeepLink = `${result.link.dynamicLinkInfo.link}`; // Replace with your actual app deep link
    const playStoreLink = `https://play.google.com/store/apps/details?id=${result.link.dynamicLinkInfo.androidInfo.androidPackageName}&url=${result.link.dynamicLinkInfo.link}&pcampaignid=fdl_long&`
    const appStoreLink = `https://apps.apple.com/us/app/id${result.link.dynamicLinkInfo.iosInfo.iosBundleId}`;
    const webLink = `${result.link.dynamicLinkInfo.link}`;
    document.getElementById("cardImage").src = result.card.frontView

    switch (platform) {
        case 'Android':
            // openAppWithDeepLink(appDeepLink, playStoreLink);
            document.getElementById("button").addEventListener("click", () => openAppWithDeepLink(appDeepLink, playStoreLink));
            openAppWithDeepLink(appDeepLink, playStoreLink)
            break;

        case 'iOS':
            if (iOSVersion !== null) {
                if (iOSVersion >= 9) {
                    // iOS 9 and above, use Universal Links if possible
                    openAppWithDeepLink(appDeepLink, appStoreLink);
                } else {
                    // iOS below 9, use Custom Scheme as fallback
                    openAppWithDeepLink(appDeepLink, appStoreLink);
                }
                document.getElementById("button").addEventListener("click", () => openAppWithDeepLink(appDeepLink, appStoreLink));
            } else {
                // iOS detected, but version is undetermined, fallback to App Store
                document.getElementById("button").addEventListener("click", () => openAppWithDeepLink(appDeepLink, appStoreLink));
                openAppWithDeepLink(appDeepLink, playStoreLink)
            }
            break;
        default:
            console.error('Unknown platform');
    }

    document.getElementById("button").style.display = "inline-block";
    document.getElementById("loader").style.display = "none";
}

// Run the dynamic link handler on page load or link click
// Define an async function to fetch the short link
async function getShortLink() {
    // Get the current path from the URL
    const path = window.location.pathname.replace("/", "");

    // Prepare the request payload
    const data = {
        "linkId": path
    };

    // Set the headers
    const headers = {
        "Content-Type": "application/json"
    };

    // API URL to get the short link
    try {
        // Send POST request using axios
        const response = await axios.post(`${FIREBASE_API_URL}/shortlink`, data, { headers });

        // Get the result from the response
        const result = response.data;

        // Handle the dynamic link once the DOM is fully loaded
        // document.addEventListener("DOMContentLoaded", function () {
        handleDynamicLink(result);
        // });
    } catch (error) {
        document.getElementById("qr-content").style.display = "inline-block";
        document.getElementById("loader").style.display = "none";
    }
}

// Function to handle the dynamic link (e.g., display the result)
// function handleDynamicLink(result) {
//   const resultElement = document.getElementById("result");
//   resultElement.innerHTML = `Short link: ${result.shortLink}`;  // Adjust based on the actual response
// }
document.addEventListener("DOMContentLoaded", function () {
    displayElements();
});

function displayElements(){
    const platform = getPlatform();
    if (platform === 'Desktop') {
        document.getElementById("button").style.display = "none";
    } else {
        document.getElementById("qr-content").style.display = "none";
        document.getElementById("loader").style.display = "inline-block";
        document.getElementById("button").style.display = "none";

        const path = window.location.pathname.replace("/", "");
        if (path === '') {
            document.getElementById("qr-content").style.display = "inline-block";
            document.getElementById("loader").style.display = "none";
        } else {
            getShortLink();
        }
    }
}